import React from "react";
import FaqCard from "./FaqCard";

import { useTranslation } from "react-i18next";
import select_logo from "../../images/select__logo.svg";
import "../../i18next";
import "./Faq.css";
export default function Faq() {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <section className="faq">
      <div
        className="select__container"
        style={{
          backgroundImage: `url(${select_logo})`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 250,
          marginBottom: 30,
          borderBottom: "1px solid #2D303F",
        }}
      >
        <p className="select__title">FAQ</p>
      </div>
      <div className="faq__container">
        <div className="faq__wrap_grid">
          <FaqCard
            title="Пакет с компонентами:"
            subtitle="Microsoft Visual C++ 2008-2022"
            link="https://www.techpowerup.com/download/visual-c-redistributable-runtime-package-all-in-one/"
          />
          <FaqCard
            title="Обновление видеодрайвера:"
            subtitle="Nvidia"
            link="https://www.nvidia.ru/Download/index.aspx?lang=ru"
          />
          <FaqCard
            title="Пакет с компонентами:"
            subtitle="DirectX 11/12"
            link="https://download.microsoft.com/download/1/7/1/1718CCC4-6315-4D8E-9543-8E28A4E18C4C/dxwebsetup.exe"
          />
          <FaqCard
            title="Обновление видеодрайвера:"
            subtitle="AMD"
            link="https://www.amd.com/ru/support"
          />
          <FaqCard
            title="Пакет с компонентами:"
            subtitle="Microsoft Net Framework 4.8"
            link="https://microsoft.com/ru-ru/download/details.aspx?id=17851"
          />
          <FaqCard
            title="Logitech GHub:"
            subtitle="GHUB 2021"
            link="https://web.archive.org/web/20210902084507if_/https://download01.logi.com/web/ftp/pub/techsupport/gaming/lghub_installer.exe"
          />
        </div>
      </div>
    </section>
  );
}
